<template>
  <div class="mb-4">
    <v-row justify="center">
      <v-col xs="12" lg="4">
        <v-form ref="form" v-model="formRules.valid">
          <h3>Add Lender Address</h3>
          <v-text-field
            label="Address Label"
            v-model="address.label"
            :rules="formRules.required"
          ></v-text-field>
          <br />
          <v-text-field
            label="Suite / Apt #"
            v-model="address.suite"
          ></v-text-field>
          <v-text-field
            :rules="formRules.required"
            label="Line One"
            v-model="address.lineOne"
          ></v-text-field>
          <v-text-field
            label="Line Two"
            v-model="address.lineTwo"
          ></v-text-field>
          <v-text-field
            :rules="formRules.required"
            label="City"
            v-model="address.city"
          ></v-text-field>
          <v-text-field
            :rules="formRules.required"
            label="Province"
            v-model="address.province"
          ></v-text-field>
          <v-text-field
            label="Postal Code"
            v-model="address.postal"
          ></v-text-field>

          <v-btn
            v-if="!loading && createType == 'add'"
            dark
            @click="submitAddress"
            >Add Address</v-btn
          >
          <v-btn
            v-if="!loading && createType == 'edit'"
            dark
            @click="submitAddress"
            >Update Address</v-btn
          >

          <v-btn
            v-if="!loading"
            class="ml-4"
            dark
            @click="$router.push({path: `/dashboard/lender/read/${lenderId}`})"
            >Cancel</v-btn
          >

          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  name: "LenderAddressAdd",
  props: ["lenderAddressId"],
  data() {
    return {
      formRules: {
        valid: false,
        required: [(value) => !!value || "This is a required field."],
      },
      address: {
        suite: "",
        lineOne: "",
        lineTwo: "",
        city: "",
        province: "",
        postal: "",
      },
      createType: "",
      loading: false,
      lenderId: ''
    };
  },
  methods: {
    submitAddress() {
      try {
        this.$refs.form.validate();
        if (this.formRules.valid) {
          this.loading = true;

          // let authUser = this.$store.getters["Auth/getAuthUser"];
          // this.address.lenderId = parseInt(this.$route.query.lenderId)
          if (this.createType == "add") {
            API()
              .post("/lenderAddress", this.address)
              .then((res) => {
                this.$router.push(
                  `/dashboard/lender/read/${this.$route.query.lenderId}`
                );
              })
              .catch((e) => {
                this.submitError = e;
              });
          } else {
            API()
              .patch("/lenderAddress", this.address)
              .then((res) => {
                this.$router.push(
                  `/dashboard/lender/read/${this.address.lenderId}`
                );
              })
              .catch((e) => {
                this.submitError = e;
              });
          }
        }
      } catch (e) {
        console.log(e);
      }

      // closeNote() {
      //   this.$emit("closeNote");
      // },
    },
  },
  mounted() {
    if (this.$route.name == "lenderAddressAdd") {
      this.createType = "add";
      this.address.lenderId = parseInt(this.$route.query.lenderId);
      this.lenderId = parseInt(this.$route.query.lenderId);
      this.componentLoading = false;
    } else {
      this.loading = true;
      this.createType = "edit";
      API()
        .get(`/lenderAddress/read/${this.lenderAddressId}`)
        .then((res) => {
          console.log(res);
          this.address = res.data;
          this.lenderId = res.data.lenderId;
          this.loading = false;
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status == 401) {
              this.$store.dispatch("Auth/silentLogin");
            }
          }
          console.log(e);
        });
    }
  },
};
</script>

<style>
</style>